import React from 'react';
import EpisodeMeta from '../../episode-preview/episode-meta';
import TagList from '../../tag-list';
import './meta-content.css';

const MetaContent = (props) => {
    return (
        <aside id="MetaContent">
            <div className="row mt-2">
                <div className="meta-inner-container col-auto">
                    <EpisodeMeta date={props.episode.releaseDate} duration={props.episode.duration} />
                </div>
                <div className="meta-inner-container col-auto mt-sm-0 ml-sm-auto">
                    <div className="text-right tag-inner">
                        <span className="float-left d-inline-block mr-2">Topics: </span>
                        <TagList tags={props.episode.topics} />
                    </div>
                </div>
            </div>
        </aside>
    );
}

export default MetaContent;
