import React from 'react';
import './footer.css';
import Menu from '../homepage-header/menu';
import PageBumper from '../page-bumper';

const Footer = (props) => {

    let currentYear = new Date().getFullYear();

    return (
        <footer id="Footer">
            <section id="InnerFooter">
                <div className="row">
                    <div className="col">
                        <div className="d-flex align-items-center">
                            <div className="text-uppercase">
                                Astroskeptic (c) {currentYear}
                            </div>
                            <div id="FooterNav">
                                <Menu navList={props.nav} prependSlash={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <aside id="Bumper" className="container text-center">
                <PageBumper urls={props.urls} />
            </aside>
        </footer>
    );
}

export default Footer;
