// Settings - some should be pulled from Contentful in the future
const Settings = {
    Url: "https://www.astroskeptic.org",
    SiteTitle: "Astroskeptic",
    LongSiteTitle: "Astroskeptic Podcast",
    IntroText: `The astrology podcast for skeptics`,
    Nav: {
      episodes: "#episodes",
      about: "#about",
      chart: "#chart",
      resources: "./resources"
    },
    ServiceUrls: {
      Apple: "https://podcasts.apple.com/us/podcast/astroskeptic/id1574061375",
      Spotify: "https://open.spotify.com/show/6sJJ6fZwPn0o1oZUSRyZp2?si=0240ce9ec7834943"
    },
    Socials : [
      {
        name: "Instagram",
        url: "https://www.instagram.com/astroskepticpod/",
        handle: "@astroskepticpod",
        simpleHandle: "astroskepticpod"
      },
      {
        name: "Twitter",
        url: "https://twitter.com/astroskepticpod",
        handle: "@astroskepticpod",
        simpleHandle: "astroskepticpod"
      },
      {
        name: "TikTok",
        url: "https://www.tiktok.com/@astroskepticpod",
        handle: "@astroskepticpod",
        simpleHandle: "astroskepticpod"
      }
    ],
    DonateUrl: "https://www.buymeacoffee.com/astroskeptic"
  };

  export default Settings;