
const ConvertToAstrodienstSymbol = (symbol) => {
    switch (symbol.toLowerCase()) {
        case "aries":
            return "a"
        case "taurus":
            return "b"
        case "gemini":
            return "c"
        case "cancer":
            return "d"
        case "leo":
            return "e"
        case "virgo":
            return "f"
        case "libra":
            return "g"
        case "scorpio":
            return "h"
        case "sagittarius":
            return "i"
        case "capricorn":
            return "j"
        case "aquarius":
            return "k"
        case "pisces":
            return "l"
        case "sun":
            return "A"
        case "moon":
            return "B"
        case "mercury":
            return "C"
        case "venus":
            return "D"
        case "mars":
            return "E"
        case "jupiter":
            return "F"
        case "saturn":
            return "G"
        case "uranus":
            return "H"
        case "neptune":
            return "I"
        case "pluto":
            return "J"
        case "true node":
            return "L"
        case "chiron":
            return "N"
        case "conjunction":
            return "m"
        case "opposition":
            return "n"
        case "square":
            return "o"
        case "trine":
            return "p"
        case "sextile":
            return "q"
        case "semi-sextile":
            return "r"
        case "quincunx":
            return "s"
        case "semi-square":
            return "t"
        case "sesquiquadrate":
            return "u"
        case "quintile":
            return "v"
        case "biquintile":
            return "w"
        default:
            return ""
    }
}

export default ConvertToAstrodienstSymbol;
