import React from 'react';
import './social-widget.css';

const SocialWidget = (props) => {

    const networks = props.links.map(s => {
        let isNew = s.name.toLowerCase() === '';
        let icon = s.name.toLowerCase() + ".svg";
        let newBadge = isNew ? <small className="new-badge">New</small> : null;
        return <li key={s.name}>
            <a href={s.url} target="_blank" rel="noreferrer" title={`${s.name}: ${s.handle}`}>
                <img src={`/img/${icon}`} alt={s.name} />
                {s.simpleHandle}
                {newBadge}
            </a>
        </li>
        }
    );

    return (
        <nav id="social-media">
            <span>Visit us on social media: </span>
            <ul id="social-media-list">
                {networks}
            </ul>
        </nav>
    );
}

export default SocialWidget;
