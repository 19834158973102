import React from 'react';
import "./heading.css"

const Heading = (props) => {

    let icon = props.icon ? (
      <img src={props.icon} className="heading-icon" alt={props.text} />
    ) : null;
    let navElement = props.navFor ? (
      <a href={props.navFor.substring(1)} name={props.navFor.substring(1)}>
        &nbsp;
      </a>
    ) : null;

    return (
        <div className="pt-3 pb-3">
            {navElement}
            <h2 className={props.centered ? "text-center heading" : "heading"}> {icon} {props.text}</h2>
        </div>
    );
}

export default Heading;
