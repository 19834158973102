import React, { useState } from 'react';
import './intro.css';
import eye from '../eye.svg';
import PlayModal from '../../play-modal';
import DonateButton from '../../donate-btn';

let defaultTitle = <h4><span>Astroskeptic</span></h4>




const Intro = (props) => {
    const [show, setShowListenModal] = useState(false);
    let defaultUrls = {
        spotify: props.urls.Spotify || "",
        itunes: props.urls.Apple || ""
    };
    return (
        <div className="col">
            <div id="intro" className="row">
                <div className="intro-text m-auto">
                    <div className="col-auto text-center">
                        <h1 className="ft delay0">The astrology podcast for skeptics.</h1>
                        <p className="lead ft delay1">
                            Welcome to scientific spirituality. <br className="d-none d-sm-block" />
                            Now available on <a href={props.urls.Apple} target="_blank" rel="noreferrer">Apple Podcasts</a> <br className="d-none d-sm-block" />and <a href={props.urls.Spotify} rel="noreferrer" target="_blank">Spotify</a>.
                        </p>
                        <button className="btn btn-primary ft delay2" onClick={() => { setShowListenModal(true) }}>Listen Now</button>
                        <DonateButton anim="ft delay2" />
                    </div>
                </div>
            </div>
            <PlayModal onClose={() => setShowListenModal(false)} show={show} title={defaultTitle} links={defaultUrls} isDefaultUrls={true} />
        </div>
    );
}

export default Intro;
