import React from 'react';
import './planets.css';

// Planets
import SaturnImg from './saturn.png';
import NeptuneImg from './neptune.png';
import JupiterImg from './jupiter.png';

let planets = [
    {
        Name: "Saturn",
        Image: SaturnImg,
        AnimationDelay: "0.6s",
        Position: {
            X: -3, // as a percentage
            Y: 40
        }
    },
    {
        Name: "Neptune",
        Image: NeptuneImg,
        AnimationDelay: "1s",
        Position: {
            X: 68,
            Y: 80
        }
    },
    {
        Name: "Jupiter",
        Image: JupiterImg,
        AnimationDelay: "0.2s",
        Position: {
            X: 65,
            Y: 0
        }
    }
]

let RenderPos = (pos, delay) => {
    return {
        left: `${pos.X}%`,
        top: `${pos.Y}%`,
        animation: 'bounce 1s infinite alternate',
        animationDelay: delay
    }
}


const Planets = () => {
    return (
        <aside id="PlanetsImage">
            {
                planets.map(p => <img className={p.Name} key={p.Name} src={p.Image} style={RenderPos(p.Position, p.AnimationDelay)} />)
            }
        </aside>
    );
}

export default Planets;