import React from 'react';
import Settings from '../Settings';
import './donate-btn.css';


const DonateButton = (props) => {
    let classes = "btn donate-btn " + props.anim;
    let button =
            <a
                className={classes}
                href={Settings.DonateUrl}
                target="_blank"
                rel="noreferrer"
              >
                  <svg xmlns="http://www.w3.org/2000/svg" width="45.004" height="31.5" viewBox="0 0 45.004 31.5">
  <path id="Icon_awesome-coffee" data-name="Icon awesome-coffee" d="M13.5,27H27a6.752,6.752,0,0,0,6.75-6.75H36a9,9,0,0,0,0-18H8.438A1.683,1.683,0,0,0,6.75,3.938V20.25A6.752,6.752,0,0,0,13.5,27ZM36,6.75a4.5,4.5,0,0,1,0,9H33.75v-9Zm3.354,27H3.4c-3.347,0-4.289-4.5-2.531-4.5H41.878c1.758,0,.83,4.5-2.524,4.5Z" transform="translate(0.004 -2.25)" fill="var(--primary)"/>
</svg>

                Buy Us a Coffee
              </a>;

    return button
}

export default DonateButton;
