import React from 'react';
import './tags.css';

const TagList = (props) => {
    return (
        <ul className="tags">
            {props.tags.map((value, index) => {
                return <li key={index}>{value}</li>
            })}
        </ul>
    );
}

export default TagList;
