// Components
import React, { useState } from 'react';
import PlayModal from '../../play-modal';

// Styles, etc
import './big-thumbnail.css';
import playBtn from '../../episode-preview/play.svg'
import mobilePlayIcon from '../../episode-preview/mobile-play.svg'
import navArrow from './nav.svg'
import ConvertToAstrodienstSymbol from '../../utils';


const BigThumbnail = (props) => {

    const [show, setShow] = useState(false);

    let signs = "Aries|Taurus|Gemini|Cancer|Leo|Virgo|Libra|Scorpio|Sagittarius|Capricorn|Aquarius|Pisces|Moon|Sun|Mercury|Venus|Mars|Jupiter|Saturn|Neptune|Uranus|Pluto|Chiron";
    let planets = "Moon|Sun|Mercury|Venus|Mars|Jupiter|Saturn|Neptune|Uranus|Pluto|Chiron";
    
    let sign = props.episode.title.match(signs) !== null ? props.episode.title.match(signs)[0] : "";
    if (sign === "") sign = props.episode.title.match(planets) !== null ? props.episode.title.match(planets)[0] : "";
    let isPlanetGlyph = (props.episode.title.match(planets) !== null)
    let symbol = <span className="symbol" data-is-planet-glyph={isPlanetGlyph}>{ConvertToAstrodienstSymbol(sign)}</span>;

    var imageLoad = (e) => {
        return e.target.parentElement.classList.add("loaded")
    };

    let newTag = props.episode.isLatest ? <div className="big-thumbnail-new-tag fl delay1">latest</div> : null;
    let formattedTitle = props.episode.title.split(": ").length > 1 ? <h1><small className='delay2 fl'>{props.episode.title.split(": ")[0]}:</small><br /><span className="fl delay3">{props.episode.title.split(": ")[1]}</span></h1> : <h1 className="fl delay3">{props.episode.title}</h1>;

    let urls = {
        spotify: props.episode.spotifyUrl,
        itunes: props.episode.itunesUrl
    };

    let prev = props.episode.nav.prev ? <span onClick={() => { window.location = props.episode.nav.prev }} className="prev"><img src={navArrow} alt="<"></img>Previous</span> : null;
    let next = props.episode.nav.next ? <span onClick={() => { window.location = props.episode.nav.next }} className="next"><img src={navArrow} alt=">"></img>Next</span> : null;



    return (
        <figure className="big-thumbnail">
            <div className="big-thumbnail-nav">
                {prev}
                {next}
            </div>
            <div className="big-thumbnail-inner">
                <div className="big-thumbnail-image">
                    {symbol}
                    <img src={props.episode.image} onLoad={imageLoad} alt={`${"Image for Episode"} ${props.episode.id} of Astroskeptic`} />
                    <div className="spinner"></div>
                </div>
                <div className="big-thumbnail-text">
                    <div className="big-thumbnail-text-inner">
                        <span className="mobile-play" onClick={() => { setShow(true) }}><img src={mobilePlayIcon} alt="Play" />Tap to play</span>
                        <img className="play fl delay1" alt="Play" src={playBtn} onClick={() => { setShow(true) }}/>
                        <div>
                            {newTag}
                            {formattedTitle}
                        </div>
                    </div>
                </div>
            </div>
            <PlayModal onClose={() => setShow(false)} show={show} title={formattedTitle} links={urls} isDefaultUrls={false} />
        </figure>
    );
}

export default BigThumbnail;
