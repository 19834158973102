import React from 'react';
import Menu from './menu';
import IntroText from './intro'
import logo from './logo.svg';
import miniLogo from './eye.svg';
import './homepage-hero.css';
import Planets from './planets';

const HomepageHeader = (props) => {
    return (
        <>
                <header id="Header">
                    <section className="row align-items-center homepage-z-fix">
                        <div className="col-12 col-md-auto align-self-end">
                            <Menu isHomepage={true} navList={props.nav} />
                        </div>
                        <div className="col-auto">
                            <a href="/">
                                <img id="MiniLogo" src={miniLogo} alt={props.siteTitle} />
                            </a>
                        </div>
                    </section>
                    <section className="row align-items-center homepage-z-fix">
                        <a href="/" className="align-self-center">
                            <img id="Logo" className="fl delay0" src={logo} alt={props.siteTitle} />
                        </a>
                    </section>
                    <div className="homepage-z-fix">
                        <IntroText bigText={props.introText} urls={props.serviceUrls} />
                    </div>
                    <Planets />
                </header>
                
                </>
    );
}

export default HomepageHeader;
