import React, { Component } from 'react'
import ResourceLink from './resource-link'

export class Resources extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            resources: []
        };
    }

    componentDidMount() {
        fetch("/fetch/resources")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        resources: result
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    };

    render() {
        const { error, resources, isLoaded } = this.state;

        

        var resMap = isLoaded ? resources.map(r => <ResourceLink resource={r} />) : <small>error</small>;


        if (error) {
            return <div className="text-center mt-5">Oops! We're having issues. Check back in ten minutes.</div>;
        } else if (!isLoaded) {
            return <div className="text-center mt-5">Loading...</div>;
        } else {
        return (
            <div>
                <h1>Resources</h1>
                <ul className="resource-link-list">
                    {resMap}
                </ul>
            </div>
        )
    }}
}

export default Resources
