import React from 'react';
import ConvertToAstrodienstSymbol from "../utils/index.js"

const ElementalTable = (props) => {

    let planets = props.origin.horoscope._celestialBodies.all;
    let otherBodies = props.origin.horoscope._celestialPoints.all;
    let ac = props.origin.horoscope._ascendant;
    let mc = props.origin.horoscope._midheaven; //midheaven

    let celestialObjects = [...planets, ...otherBodies, ac, mc];
    // remove unwanted symbols
    celestialObjects = celestialObjects.filter(x => x.key !== "sirius");
    
    let getPlanet = (sign) => {

        let c = celestialObjects.filter(x => x.Sign.key === sign.toLowerCase());
        let results = c.map( e => e.label );
       
        if (results.length === 1) return results[0];
        if (results.length > 1) return results.join(', ');
        return results;
    }

    return (
        <table className="table" id="modality-elements">
            <thead>
                <tr>
                    <th></th>
                    <th>Cardinal</th>
                    <th>Fixed</th>
                    <th>Mutable</th>
                </tr>
            </thead>
            <tbody>
                <tr id="fire">
                    <td>Fire</td>
                    <td data-sign="Aries">{getPlanet("Aries")}</td>
                    <td data-sign="Leo">{getPlanet("Leo")}</td>
                    <td data-sign="Sagittarius">{getPlanet("Sagittarius")}</td>
                </tr>
                <tr id="air">
                    <td>Air</td>
                    <td data-sign="Libra">{getPlanet("Libra")}</td>
                    <td data-sign="Aquarius">{getPlanet("Aquarius")}</td>
                    <td data-sign="Gemini">{getPlanet("Gemini")}</td>
                </tr>
                <tr id="earth">
                    <td>Earth</td>
                    <td data-sign="Capricorn">{getPlanet("Capricorn")}</td>
                    <td data-sign="Taurus">{getPlanet("Taurus")}</td>
                    <td data-sign="Virgo">{getPlanet("Virgo")}</td>
                </tr>
                <tr id="water">
                    <td>Water</td>
                    <td data-sign="Cancer">{getPlanet("Cancer")}</td>
                    <td data-sign="Scorpio">{getPlanet("Scorpio")}</td>
                    <td data-sign="Pisces">{getPlanet("Pisces")}</td>
                </tr>
            </tbody>
        </table>
    );
}

const AspectSlope = (props) => {

    let aspects = Object.entries(props.origin.horoscope._aspects.points);
    
    let getSymbol = (planet1, planet2) => {

        let symbol = null;
        let locator = aspects.find(x => x[0] === planet1.toLowerCase())
        if (locator !== null && locator !== undefined) {
            symbol = locator[1].filter(x => x.point2Key === planet2.toLowerCase())[0];
        }
        if (symbol !== null && symbol !== undefined) return <span>{ConvertToAstrodienstSymbol(symbol.label)}<div id={`tooltip-${symbol.label}`} className="tooltip"><small>{symbol.label.toUpperCase()}:</small> {symbol.point1Label} &#9654; {symbol.point2Label}</div></span>;
    }

    return (
        <table className="table" id="aspect-slope">
            <tbody>
                <tr>
                    <th><div><i>A</i><span>Sun</span></div></th>
                    <th rowSpan="2"><div><i>B</i><span>Moon</span></div></th>
                    <th rowSpan="3"><div><i>C</i><span>Mercury</span></div></th>
                    <th rowSpan="4"><div><i>D</i><span>Venus</span></div></th>
                    <th rowSpan="5"><div><i>E</i><span>Mars</span></div></th>
                    <th rowSpan="6"><div><i>F</i><span>Jupiter</span></div></th>
                    <th rowSpan="7"><div><i>G</i><span>Saturn</span></div></th>
                    <th rowSpan="8"><div><i>H</i><span>Uranus</span></div></th>
                    <th rowSpan="9"><div><i>I</i><span>Neptune</span></div></th>
                    <th rowSpan="10"><div><i>J</i><span>Pluto</span></div></th>
                    <th rowSpan="11"><div><i>L</i><span>True Node</span></div></th>
                    <th rowSpan="12"><div><i>N</i><span>Chiron</span></div></th>
                    <th rowSpan="13"><div>AC</div></th>
                </tr>
                <tr data-planet="Moon">
                    <td>{getSymbol("Sun", "Moon")}</td>
                </tr>
                <tr data-planet="Mercury">
                    <td>{getSymbol("Sun", "Mercury")}</td>
                    <td>{getSymbol("Moon", "Mercury")}</td>
                </tr>
                <tr data-planet="Venus">
                    <td>{getSymbol("Sun", "Venus")}</td>
                    <td>{getSymbol("Moon", "Venus")}</td>
                    <td>{getSymbol("Mercury", "Venus")}</td>
                </tr>
                <tr data-planet="Mars">
                    <td>{getSymbol("Sun", "Mars")}</td>
                    <td>{getSymbol("Moon", "Mars")}</td>
                    <td>{getSymbol("Mercury", "Mars")}</td>
                    <td>{getSymbol("Venus", "Mars")}</td>
                </tr>
                <tr data-planet="Jupiter">
                    <td>{getSymbol("Sun", "Jupiter")}</td>
                    <td>{getSymbol("Moon", "Jupiter")}</td>
                    <td>{getSymbol("Mercury", "Jupiter")}</td>
                    <td>{getSymbol("Venus", "Jupiter")}</td>
                    <td>{getSymbol("Mars", "Jupiter")}</td>
                </tr>
                <tr data-planet="Saturn">
                    <td>{getSymbol("Sun", "Saturn")}</td>
                    <td>{getSymbol("Moon", "Saturn")}</td>
                    <td>{getSymbol("Mercury", "Saturn")}</td>
                    <td>{getSymbol("Venus", "Saturn")}</td>
                    <td>{getSymbol("Mars", "Saturn")}</td>
                    <td>{getSymbol("Jupiter", "Saturn")}</td>
                </tr>
                <tr data-planet="Uranus">
                    <td>{getSymbol("Sun", "Uranus")}</td>
                    <td>{getSymbol("Moon", "Uranus")}</td>
                    <td>{getSymbol("Mercury", "Uranus")}</td>
                    <td>{getSymbol("Venus", "Uranus")}</td>
                    <td>{getSymbol("Mars", "Uranus")}</td>
                    <td>{getSymbol("Jupiter", "Uranus")}</td>
                    <td>{getSymbol("Saturn", "Uranus")}</td>
                </tr>
                <tr data-planet="Neptune">
                    <td>{getSymbol("Sun", "Neptune")}</td>
                    <td>{getSymbol("Moon", "Neptune")}</td>
                    <td>{getSymbol("Mercury", "Neptune")}</td>
                    <td>{getSymbol("Venus", "Neptune")}</td>
                    <td>{getSymbol("Mars", "Neptune")}</td>
                    <td>{getSymbol("Jupiter", "Neptune")}</td>
                    <td>{getSymbol("Saturn", "Neptune")}</td>
                    <td>{getSymbol("Uranus", "Neptune")}</td>
                </tr>
                <tr data-planet="Pluto">
                    <td>{getSymbol("Sun", "Pluto")}</td>
                    <td>{getSymbol("Moon", "Pluto")}</td>
                    <td>{getSymbol("Mercury", "Pluto")}</td>
                    <td>{getSymbol("Venus", "Pluto")}</td>
                    <td>{getSymbol("Mars", "Pluto")}</td>
                    <td>{getSymbol("Jupiter", "Pluto")}</td>
                    <td>{getSymbol("Saturn", "Pluto")}</td>
                    <td>{getSymbol("Uranus", "Pluto")}</td>
                    <td>{getSymbol("Neptune", "Pluto")}</td>
                </tr>
                <tr data-planet="NorthNode">
                    <td>{getSymbol("Sun", "NorthNode")}</td>
                    <td>{getSymbol("Moon", "NorthNode")}</td>
                    <td>{getSymbol("Mercury", "NorthNode")}</td>
                    <td>{getSymbol("Venus", "NorthNode")}</td>
                    <td>{getSymbol("Mars", "NorthNode")}</td>
                    <td>{getSymbol("Jupiter", "NorthNode")}</td>
                    <td>{getSymbol("Saturn", "NorthNode")}</td>
                    <td>{getSymbol("Uranus", "NorthNode")}</td>
                    <td>{getSymbol("Neptune", "NorthNode")}</td>
                    <td>{getSymbol("Pluto", "NorthNode")}</td>
                </tr>
                <tr data-planet="Chiron">
                    <td>{getSymbol("Sun", "Chiron")}</td>
                    <td>{getSymbol("Moon", "Chiron")}</td>
                    <td>{getSymbol("Mercury", "Chiron")}</td>
                    <td>{getSymbol("Venus", "Chiron")}</td>
                    <td>{getSymbol("Mars", "Chiron")}</td>
                    <td>{getSymbol("Jupiter", "Chiron")}</td>
                    <td>{getSymbol("Saturn", "Chiron")}</td>
                    <td>{getSymbol("Uranus", "Chiron")}</td>
                    <td>{getSymbol("Neptune", "Chiron")}</td>
                    <td>{getSymbol("Pluto", "Chiron")}</td>
                    <td>{getSymbol("NorthNode", "Chiron")}</td>
                </tr>
                <tr data-planet="Ascendant">
                    <td>{getSymbol("Sun", "Ascendant")}</td>
                    <td>{getSymbol("Moon", "Ascendant")}</td>
                    <td>{getSymbol("Mercury", "Ascendant")}</td>
                    <td>{getSymbol("Venus", "Ascendant")}</td>
                    <td>{getSymbol("Mars", "Ascendant")}</td>
                    <td>{getSymbol("Jupiter", "Ascendant")}</td>
                    <td>{getSymbol("Saturn", "Ascendant")}</td>
                    <td>{getSymbol("Uranus", "Ascendant")}</td>
                    <td>{getSymbol("Neptune", "Ascendant")}</td>
                    <td>{getSymbol("Pluto", "Ascendant")}</td>
                    <td>{getSymbol("NorthNode", "Ascendant")}</td>
                    <td>{getSymbol("Chiron", "Ascendant")}</td>
                </tr>
                <tr data-planet="Midheaven">
                    <td>{getSymbol("Sun", "Midheaven")}</td>
                    <td>{getSymbol("Moon", "Midheaven")}</td>
                    <td>{getSymbol("Mercury", "Midheaven")}</td>
                    <td>{getSymbol("Venus", "Midheaven")}</td>
                    <td>{getSymbol("Mars", "Midheaven")}</td>
                    <td>{getSymbol("Jupiter", "Midheaven")}</td>
                    <td>{getSymbol("Saturn", "Midheaven")}</td>
                    <td>{getSymbol("Uranus", "Midheaven")}</td>
                    <td>{getSymbol("Neptune", "Midheaven")}</td>
                    <td>{getSymbol("Pluto", "Midheaven")}</td>
                    <td>{getSymbol("NorthNode", "Midheaven")}</td>
                    <td>{getSymbol("Chiron", "Midheaven")}</td>
                    <th>MC</th>
                </tr>
            </tbody>
        </table>
    );
}



export { AspectSlope, ElementalTable };
