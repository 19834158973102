import React, { useEffect } from "react";
import './instafeed.css';

const Instafeed = () => {

    useEffect(() => {
        const instaScript = document.createElement("script");
        instaScript.src = "js/insta.js?v=2";
        instaScript.async = false;
        document.body.appendChild(instaScript);
    });

    return (
        <div>
            <div id="curator-feed-default-feed-layout" className="text-center text-muted">
                <a href="https://curator.io" target="_blank" className=" crt-logo crt-tag">Powered by Curator.io</a>
            </div>
        </div>
    );
}

export default Instafeed;
