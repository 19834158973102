import React from 'react';

const PlaceBox = (props) => {
        function rejectEnterKey(evt) {
            if (evt.nativeEvent.which === 13) {
                evt.preventDefault();
            }
        }
        return (
            <>
                <div className="lp">
                    <input onReset={(e) => props.onPlaceSelect(e)} onKeyUp={e => rejectEnterKey(e)} id="place" className="form-control mt-2" type="text" placeholder="Start typing your location..." data-latitude="" data-longitude="" ref={props.elemRef} />
                </div>
            </>
        );
};

export default PlaceBox;


