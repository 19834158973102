import React from 'react';
import './subpage-logo.css';
import eye from './eye-small.svg';

function SubpageLogo() {
    return (
        <div id="SubpageLogo" className="delay0 fl">
            <a href="/">
                <img src={eye} alt="Astroskeptic Podcast" />
                <h1 className="text-uppercase d-none d-md-inline-block">Astroskeptic</h1>
            </a>
        </div>
    )
}

export default SubpageLogo
