import React from 'react';
import '../episode-listing-table.css'
import TagList from '../../tag-list';

const EpisodeListingTableRow = (props) => {

    let beacon = props.episode.isLatest ? <span className="beacon"></span> : null;
    let formattedReleaseDate = new Date(props.episode.releaseDate).toLocaleDateString("en-US");
    let episodeLink = `/episodes/${props.episode.id.toLowerCase()}`;


    let sendTo = (e) =>{
        window.location = e.currentTarget.dataset.episodeLink;
    }

    return (
        <tr className="episode-row" onClick={sendTo} data-episode-link={episodeLink}>
            <td className="episode-latest-beacon" data-contains="beacon">{beacon}</td>
            <td className="episode-id" data-contains="id">{props.episode.id}</td>
            <td className="episode-title" data-contains="title">
                {props.episode.title}
                <div className="d-block mt-1 mb-2 d-sm-none small">
                    <TagList tags={props.episode.topics} />
                </div>
            </td>
            <td className="episode-topics" data-contains="topics">
                <TagList tags={props.episode.topics}/>
            </td>
            <td className="episode-release-date" data-contains="date">{formattedReleaseDate}</td>
        </tr>
    );
}

export default EpisodeListingTableRow;
