import React, { useEffect, useState, useRef } from "react";
import { Origin, Horoscope } from "circular-natal-horoscope-js";
import './natal-chart.css';
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import PlaceBox from './placebox';
import { ElementalTable, AspectSlope } from "../_natal-tables";


const DEBUG = false;

var defaultNatalData = {
  planets: {
    Lilith: [0],
    Chiron: [0],
    Pluto: [0],
    Neptune: [0],
    Uranus: [0],
    Saturn: [0],
    Jupiter: [0],
    Mars: [0],
    Moon: [0],
    Sun: [0],
    Mercury: [0],
    Venus: [0],
    NNode: [0]
  },
  cusps: new Array(12).fill(0)
};

// Alex test data
var debugNatalData = { "planets": { "Lilith": [181.1897], "Chiron": [92.3313], "Pluto": [225.1887], "Neptune": [281.5898], "Uranus": [274.2888], "Saturn": [280.8296], "Jupiter": [57.5035], "Mars": [47.4181], "Moon": [125], "Sun": [329.7917], "Mercury": [303.3689], "Venus": [318.4625], "NNode": [335] }, "cusps": [301.9792, 350.6796, 29.3268, 56.0537, 77.3215, 97.6116, 121.9792, 170.6796, 209.3268, 236.0537, 257.3215, 277.6116] };

const NatalChart = (props) => {
  var defaults = {
    horoscope: new Horoscope({
      origin: new Origin({
        year: 1989,
        month: 1, // 0 = January, 11 = December!
        date: 1,
        hour: 6,
        minute: 24,
        latitude: 42.963795,
        longitude: -85.670006
      }),
      houseSystem: "placidus",
      zodiac: "tropical",
      aspectPoints: ["bodies", "points", "angles"],
      aspectWithPoints: ["bodies", "points", "angles"],
      aspectTypes: ["major", "minor"],
      customOrbs: {
        conjunction: 10,
        opposition: 10,
        trine: 10,
        square: 10,
        sextile: 6,
        quincunx: 3,
        quintile: 1,
        septile: 1,
        "semi-square": 2.5,
        "semi-sextile": 3
      },
      language: "en"
    }),
    buttonDisabled: true,
    isDimmed: true,
    chartUpdateCount: 0,
    natal: !DEBUG ? defaultNatalData : debugNatalData
  };
  const [data, setData] = useState(defaults);
  const scriptRef = useRef();
  const placeRef = useRef();
  const datetime = useRef();
  const placeScriptRef = useRef();

  useEffect(() => {

    var COLOR_FIRE = "#9E6D64";
    var COLOR_EARTH = "#A4B791";
    var COLOR_AIR = "#FFEFC7";
    var COLOR_WATER = "#B6E1F2";

    var natalScript;
    var chartSettings = {
      SYMBOL_SCALE: 1.2,
      CIRCLE_COLOR: "rgba(0,0,0,0.2)",
      CIRCLE_STRONG: 2,
      MARGIN: 50,
      COLOR_BACKGROUND: "TRANSPARENT",
      RULER_RADIUS: 2.2,
      INNER_CIRCLE_RADIUS_RATIO: 10,
      INDOOR_CIRCLE_RADIUS_RATIO: 2,
      POINTS_TEXT_SIZE: 10,
      COLOR_ARIES: COLOR_FIRE,
      COLOR_TAURUS: COLOR_EARTH,
      COLOR_GEMINI: COLOR_AIR,
      COLOR_CANCER: COLOR_WATER,
      COLOR_LEO: COLOR_FIRE,
      COLOR_VIRGO: COLOR_EARTH,
      COLOR_LIBRA: COLOR_AIR,
      COLOR_SCORPIO: COLOR_WATER,
      COLOR_SAGITTARIUS: COLOR_FIRE,
      COLOR_CAPRICORN: COLOR_EARTH,
      COLOR_AQUARIUS: COLOR_AIR,
      COLOR_PISCES: COLOR_WATER,
      ASPECTS : {
        conjunction: { "degree": 10, "orbit": 10, "color": "PURPLE" },
        square: { "degree": 90, "orbit": 9, "color": "rgb(170, 8, 8)" },
        trine: { "degree": 120, "orbit": 9, "color": "rgb(42, 131, 209)" },
        opposition: { "degree": 180, "orbit": 10, "color": "rgb(170, 8, 8)" }
      },
      SYMBOL_PLUTO_ICON: "./symbols/Pluto.svg",
      SYMBOL_URANUS_ICON: "./symbols/Uranus.svg",
      ADD_CLICK_AREA: true,
      COLLISION_RADIUS: 12,
      DEBUG : DEBUG ? true : false
    }

    if (window.google === undefined) {
      // init
      const script = document.createElement("script");
      script.src = "js/astrochart.min.js";
      script.async = false;
      script.onload = () => {
        natalScript = document.createElement("script");
        natalScript.innerHTML =
          `var chart = new astrology.Chart("natal", 900, 900, ${JSON.stringify(chartSettings)});
                chart.radix(` +
          JSON.stringify(data.natal) +
          `).aspects();
            
          function findObjectCoords(mouseEvent)
            {
              var obj = document.getElementById("natal");
              var obj_left = 0;
              var obj_top = 0;
              var xpos;
              var ypos;
              while (obj.offsetParent)
              {
                obj_left += obj.offsetLeft;
                obj_top += obj.offsetTop;
                obj = obj.offsetParent;
              }
              if (mouseEvent)
              {
                xpos = mouseEvent.pageX;
                ypos = mouseEvent.pageY;
              }
              return {
                x: xpos -= obj_left,
                y: ypos -= obj_top
              };
            };

            function showAspectTooltip(evt) {
              let tooltip = document.getElementById("tooltip");
              let chart = document.getElementById("natal");
              evt.target.classList.add("active-line");
              tooltip.innerHTML = "<strong class='small'>" + evt.target.dataset.name.toUpperCase() + ":</strong> " + evt.target.dataset.point + " <small>&#9654;</small> " + evt.target.attributes["data-toPoint"].value;
              let fromElement = document.getElementById("natal-astrology-radix-planets-" + evt.target.dataset.point);
              let toElement = document.getElementById("natal-astrology-radix-planets-" + evt.target.attributes["data-toPoint"].value);
              tooltip.style.display = "block";
              let middle = Math.ceil((evt.target.dataset.x2 - evt.target.dataset.x1) / 2);
              tooltip.style.left = (findObjectCoords(evt).x - Math.ceil(tooltip.scrollWidth / 2)) + 'px';
              tooltip.style.top = (findObjectCoords(evt).y - Math.ceil(tooltip.scrollWidth / 2) + 25) + 'px';
            }

            function showPlanetTooltip(evt) {
              let tooltip = document.getElementById("tooltip");
              let chart = document.getElementById("natal");
              tooltip.innerHTML = evt.target.parentElement.id.split("-")[evt.target.parentElement.id.split("-").length - 1]
              tooltip.style.display = "block";
              tooltip.style.left = (findObjectCoords(evt).x - Math.ceil(tooltip.scrollWidth / 2)) + 'px';
              tooltip.style.top = (findObjectCoords(evt).y - Math.ceil(tooltip.scrollWidth / 2) - 35) + 'px'
            }

            function showSignTooltip(evt) {
              let tooltip = document.getElementById("tooltip");
              let chart = document.getElementById("natal");
              tooltip.innerHTML = evt.target.parentElement.id.split("-")[evt.target.parentElement.id.split("-").length - 1]
              tooltip.style.display = "block";
              tooltip.style.left = (findObjectCoords(evt).x - Math.ceil(tooltip.scrollWidth / 2)) + 'px';
              tooltip.style.top = (findObjectCoords(evt).y - Math.ceil(tooltip.scrollWidth / 2) - 35) + 'px'
            }

            function hideTooltip(evt) {
              var tooltip = document.getElementById("tooltip");
              tooltip.style.display = "none";
              evt.target.classList.remove("active-line")
            }

            // Aspect Tooltips
            let aspectTooltips = [...document.getElementById('natal-astrology-aspects').children];
            aspectTooltips.forEach(x => x.addEventListener('mouseover', e => window.showAspectTooltip(e)));

            // Planet Tooltips
            let planetTooltips = [...document.getElementById('natal-astrology-radix-planets').children].filter(x => x.id !== "");
            planetTooltips.forEach(x => x.addEventListener('mouseover', e => window.showPlanetTooltip(e)));

            // Sign Tooltips
            let signTooltips = [...document.getElementById('natal-astrology-radix-signs').children].filter(x => x.id !== "" && x.nodeName === "g");
            signTooltips.forEach(x => x.addEventListener('mouseover', e => {
              window.showSignTooltip(e);
            }));

            // All tips unbind
            aspectTooltips.forEach(x => x.addEventListener('mouseout', e => window.hideTooltip(e)));
            planetTooltips.forEach(x => x.addEventListener('mouseout', e => window.hideTooltip(e)));
            signTooltips.forEach(x => x.addEventListener('mouseout', e => window.hideTooltip(e)));
            `;
        const natalScriptTag = scriptRef.current;
        natalScriptTag.appendChild(natalScript);
      }
      document.body.appendChild(script);

      // Places API load
      const geoIPScript = document.createElement("script");
      geoIPScript.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBfahaNw3UBnFfLbA9N8YcsEpk1x6rp21A&libraries=places";
      geoIPScript.async = true;
      geoIPScript.onload = () => {
        const geoIPScriptInner = document.createElement("script");
        geoIPScriptInner.innerHTML = `
            var placeElem = document.getElementById('place');
            var autocomplete = new google.maps.places.Autocomplete(placeElem,{types: ['(cities)']});
            google.maps.event.addListener(autocomplete, 'place_changed', function(e){
              var place = autocomplete.getPlace();
              placeElem.dataset.latitude = place.geometry.location.lat();
              placeElem.dataset.longitude = place.geometry.location.lng();
              var ev = new Event('reset', { bubbles: true});
              ev.simulated = true;
              placeElem.dispatchEvent(ev);
            })

            
          `;
        const placeScriptRefTag = placeScriptRef.current;
        placeScriptRefTag.appendChild(geoIPScriptInner);
      };
      document.body.appendChild(geoIPScript);
    } else {
      //update existing

      if (data.natal !== null) {
        window.chart.radix(data.natal).aspects();

        doTips();

      }
    }
  }, [data]);

function doTips() {
  // Aspect Tooltips
  let aspectTooltips = [...document.getElementById('natal-astrology-aspects').children];
  aspectTooltips.forEach(x => x.addEventListener('mouseover', e => window.showAspectTooltip(e)));

  // Planet Tooltips
  let planetTooltips = [...document.getElementById('natal-astrology-radix-planets').children].filter(x => x.id !== "");
  planetTooltips.forEach(x => x.addEventListener('mouseover', e => window.showPlanetTooltip(e)));

  // Sign Tooltips
  let signTooltips = [...document.getElementById('natal-astrology-radix-signs').children].filter(x => x.id !== "" && x.nodeName === "g");
  signTooltips.forEach(x => x.addEventListener('mouseover', e => {
    window.showSignTooltip(e);
  }));

  // All tips unbind
  aspectTooltips.forEach(x => x.addEventListener('mouseout', e => window.hideTooltip(e)));
  planetTooltips.forEach(x => x.addEventListener('mouseout', e => window.hideTooltip(e)));
  signTooltips.forEach(x => x.addEventListener('mouseout', e => window.hideTooltip(e)));
}

  function updateOrigin(event) {
    // kick out statements
    if (event === null) return false;
    let newOrigin = data.horoscope.origin;

    let date = newOrigin.localTime.toDate();

    // set date
    date = new Date(datetime.current.getInputValue());

    // more kick outs
    if (
      isNaN(date) ||
      date === "" ||
      date === "0"
    )
      return false;
    // map Date object to Origin properties
    newOrigin.month = date.getMonth();
    newOrigin.date = date.getDate();
    newOrigin.year = date.getFullYear();
    // hour updated below as result of DST from Timezone API
    newOrigin.hour = date.getHours();
    newOrigin.minute = date.getMinutes();


    newOrigin.latitude = placeRef.current.dataset.latitude || 0;
    newOrigin.longitude = placeRef.current.dataset.longitude || 0;
    // console.log(`Lat: ${newOrigin.latitude}, Long: ${newOrigin.longitude}`);

    let timestamp = date.getTime() / 1000;
    fetch(`https://maps.googleapis.com/maps/api/timezone/json?location=${newOrigin.latitude},${newOrigin.longitude}&timestamp=${timestamp}&key=AIzaSyBfahaNw3UBnFfLbA9N8YcsEpk1x6rp21A`)
      .then(
        function (response) {
          response.json().then(function (data) {
            let newHour;
            newHour = date.getHours();
            newOrigin.hour = newHour;
            updateChart(newOrigin);
          });
        }
      ).catch(function (err) {
        console.log('Fetch Error: ', err);
      });
  }


  let elemAttributes = {
    placeholder: 'Select your birth date and time...',
    className: 'natal-chart-picker form-control',
    readOnly: true
  }

  var jumpToTime = (clh, evt) => {
    datetime.current.navigate("time");
    clh(evt);
  }

  var closeCalendar = (e) => {
    datetime.current._closeCalendar()
  }

  function calendarCloseBtn (mode, renderDefault) {
    if (mode === "time") {
      return (
        <div className="wrapper">
          {renderDefault()}
          <div className="controls text-center">
            <button className="btn btn-secondary mb-2" onClick={(e) => closeCalendar(e)}>Close</button>
          </div>
        </div>
      );
    } else {
      return renderDefault()
    }
  }


  var buttonCheck = () => {
    if ((placeRef.current.dataset.latitude !== "" &&
      placeRef.current.dataset.latitude !== 0 &&
      placeRef.current.dataset.longitude !== "" &&
      placeRef.current.dataset.longitude !== 0) && (datetime.current.getInputValue() !== "")) {
      setData(prevState => {
        return { ...prevState, buttonDisabled: false };
      } );
    } else {
      setData(prevState => {
        return { ...prevState, buttonDisabled: true };
      } );
    }
  }


  function renderDays(props, currentDate, selectedDate) {
    let existingClickHandler = props.onClick;
    return <td {...props} onClick={(e) => jumpToTime(existingClickHandler, e)}>{currentDate.date()}</td>;
  }

  function updateChart(org) {
    let newHoroscope = {
      horoscope: new Horoscope({
        origin: new Origin(org),
        houseSystem: defaults.houseSystem,
        zodiac: defaults.zodiac,
        aspectPoints: defaults.aspectPoints,
        aspectWithPoints: defaults.aspectWithPoints,
        aspectTypes: defaults.aspectTypes,
        customOrbs: defaults.customOrbs,
        language: defaults.language
      }),
    };

    let updatedData = {
      buttonDisabled: true,
      horoscope: newHoroscope.horoscope,
      chartUpdateCount: ++data.chartUpdateCount,
      natal: {
        planets: {
          Lilith: [newHoroscope.horoscope.CelestialPoints.lilith.ChartPosition.Ecliptic.DecimalDegrees],
          Chiron: [newHoroscope.horoscope.CelestialBodies.chiron.ChartPosition.Ecliptic.DecimalDegrees],
          Pluto: [newHoroscope.horoscope.CelestialBodies.pluto.ChartPosition.Ecliptic.DecimalDegrees],
          Neptune: [newHoroscope.horoscope.CelestialBodies.neptune.ChartPosition.Ecliptic.DecimalDegrees],
          Uranus: [newHoroscope.horoscope.CelestialBodies.uranus.ChartPosition.Ecliptic.DecimalDegrees],
          Saturn: [newHoroscope.horoscope.CelestialBodies.saturn.ChartPosition.Ecliptic.DecimalDegrees],
          Jupiter: [newHoroscope.horoscope.CelestialBodies.jupiter.ChartPosition.Ecliptic.DecimalDegrees],
          Mars: [newHoroscope.horoscope.CelestialBodies.mars.ChartPosition.Ecliptic.DecimalDegrees],
          Moon: [newHoroscope.horoscope.CelestialBodies.moon.ChartPosition.Ecliptic.ArcDegrees.degrees],
          Sun: [newHoroscope.horoscope.CelestialBodies.sun.ChartPosition.Ecliptic.DecimalDegrees],
          Mercury: [newHoroscope.horoscope.CelestialBodies.mercury.ChartPosition.Ecliptic.DecimalDegrees],
          Venus: [newHoroscope.horoscope.CelestialBodies.venus.ChartPosition.Ecliptic.DecimalDegrees],
          NNode: [newHoroscope.horoscope.CelestialPoints.northnode.ChartPosition.Ecliptic.ArcDegrees.degrees]
        },
        cusps: newHoroscope.horoscope.Houses.flatMap(x => x.ChartPosition.StartPosition.Ecliptic.DecimalDegrees)
      }
    };

    setData(updatedData);
  }


  let dimmedChart = !DEBUG ? (data.isDimmed ? "dimmed" : null) : null;
  let hideControls = !DEBUG ? null : "d-none";
  let buttonStatus = data.buttonDisabled ? true : false;
  let buttonText = data.chartUpdateCount === 0 ? "Create" : "Update";


  return (
    <section id="chart-container">
      <div className="row">
        <div className="col">
          <div className="mt-2 mb-3 text-center">
            <h4>Generate your natal chart below.
              <small className="ml-2"><sup className="badge badge-secondary">BETA</sup></small>
            </h4>
            <p>Provide a date and time of birth and location of birth.</p>
          </div>
        </div>
      </div>
      <div id="natal-controls" className={hideControls}>
      <div className="form-row justify-content-center">
        <div className="col-12 col-md-5">
            <Datetime onClose={buttonCheck} renderView={(mode, renderDefault) => calendarCloseBtn(mode, renderDefault)} renderDay={renderDays} initialViewMode={"years"} updateOnView={"days"} inputProps={elemAttributes} ref={datetime} />
            <PlaceBox onPlaceSelect={buttonCheck} elemRef={placeRef} />
        </div>
      </div>
      <div className="form-row">
        <div className="col mt-3">
            <button id="chartButton" className="btn btn-primary m-auto d-block" data-option={buttonText} disabled={buttonStatus} onClick={updateOrigin}>{buttonText}</button>
        </div>
      </div>
      </div>
      <figure id="natal" className={dimmedChart}>
        <p className="warning">Select date, time, and location above</p>
        <div id="tooltip" className="tooltip-hidden"></div>
      </figure>
      <section id="natal-tables" className={dimmedChart}>
        <ElementalTable origin={data} />
        <AspectSlope origin={data} />
      </section>
      <hr />
      <small className="text-muted mt-4">
        Astral calculations courtesy of <i>Jade Ahking's</i><a href="https://github.com/0xStarcat/CircularNatalHoroscopeJS"> <i>CircularNatalHoroscopeJS</i> </a>Library<br />
        Charting made possible by <a href="https://github.com/AstroDraw/AstroChart"><i>AstroChart</i>, </a> courtesy of user Kibo; maintained by user <i>afucher</i>.
      </small>
      <div id="scripts" ref={scriptRef}></div>
      <div id="placeScripts" ref={placeScriptRef}></div>

    </section>
  );
}

export default NatalChart