import React from 'react';

const Bio = (props) => {

    let revaButton = <div className="col-12 text-center">
        <a href="/wheres-reva">Where's Reva?</a>
        </div>;

    let showRevaButton = !props.showReva ? revaButton : null;

    return (
        <>
            <div className="col-12 col-md-8 text-center m-auto">
                <h5 className="text-gold">{props.name}</h5>
                <p>{props.bio}</p>
            </div>
            {showRevaButton}
        </>
    );
}

export default Bio;
