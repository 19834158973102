import React from 'react';
import './chart.css';
import chartHeadingIcon from '../heading/chart.svg';
import Heading from '../heading';

const Chart = (props) => {
    return (
        <section id="Chart" className="d-flex">
            <div className="row align-self-center">
                <div className="col-12 col-md-7 col-sm-6">
                    <Heading text={`Chart`} centered={false} icon={chartHeadingIcon} navFor={props.navFor} />
                    <p>We've built our own natal chart system right here on astroskeptic.org.</p>
                    <a href="/chart" className="btn btn-primary mt-1">Get your chart</a>
                </div>

            </div>
            <div id="ChartBumper" className="row">
                <div className="col-12 col-md-4 col-sm-6">
                    <i></i>
                </div>
            </div>
        </section>
    );
}

export default Chart;
