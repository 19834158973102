import React from 'react';
import AppleBadge from './badge-apple-podcasts.png';
import SpotifyBadge from './badge-spotify.png';
import './page-bumper.css';

const PageBumper = (props) => {
    return (
        <div className="row d-inline-block w-100">
            <a href={props.urls.Spotify}>
                <img src={SpotifyBadge} alt="Listen on Spotify" />
            </a>
            <a href={props.urls.Apple}>
                <img src={AppleBadge} alt="Listen on Apple Podcasts" />
            </a>
        </div>
    );
}

export default PageBumper;
