import React from 'react';


const EpisodeMeta = (props) => {
    let formattedReleaseDate = props.date ? new Date(props.date).toLocaleString().split(",")[0] : new Date.now().toLocaleString().split(",")[0];
    let formattedDuration = props.duration + "m";

    return (<div className="episode-meta-small">
        <span className="episode-duration">{formattedDuration}</span>
        <span className="episode-date">{formattedReleaseDate}</span>
    </div>);
}

export default EpisodeMeta;
