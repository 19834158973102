import React, { useState } from "react";

const FORM_ENDPOINT = "https://formspree.io/f/xrgjpajo";

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(0);
  const handleSubmit = e => {
    e.preventDefault();
    setSubmitted(-1);
    const inputs = e.target.elements;
    const data = {};

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].name) {
        data[inputs[i].name] = inputs[i].value;
      }
    }

    fetch(FORM_ENDPOINT, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    }).then( response => {
        debugger;
        setTimeout(() => {
            setSubmitted(1);
          }, 2000);
    })
    
  };

  if (submitted === 1) {
    return (
      <section id="contact-us-wrapper">
          <div className="text-center">
            <br />
            <h3>Thank you!</h3>
            <div>We'll be in touch soon.</div>
            <br />
            <br />
          </div>
      </section>
    );
  }
  if (submitted === -1) {
    return (
      <section id="contact-us-wrapper">
          <div className="spinner-wrapper">
            <br />
            <br />
            <div className="spinner"></div>
            <br />
            <br />
          </div>
      </section>
    );
  }

  return (
    <section id="contact-us-wrapper">
        <div className="col-12 col-sm-10 col-md-8 col-lg-6 mx-auto">
            <div className="text-center">Have a question? Feel free to ask us by filling out the form below. We may answer your question in a future episode.</div>
            <br />
            <form
            action={FORM_ENDPOINT}
            onSubmit={handleSubmit}
            method="POST"
            target="_blank"
            >
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input id="name" name="name" type="text" className="form-control" required="required" />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input id="email" name="email" required="required" type="text" className="form-control" />
                </div>
                <div className="form-group">
                    <label htmlFor="question">Question</label>
                    <textarea id="question" name="question" cols={40} rows={3} aria-describedby="questionHelpBlock" required="required" className="form-control" defaultValue={""} />
                    <span id="questionHelpBlock" className="form-text text-muted">Please enter your question above.</span>
                </div>
                <div className="form-group">
                    <button name="submit" type="submit" className="btn btn-primary">Contact</button>
                </div>
            </form>
        </div>
    </section>
  
  
  );
};

export default ContactForm;