import React from 'react';
import './menu.css';
import menuIcon from './menu.svg';
import menuIconHomepage from './menu-homepage.svg';

const Menu = (props) => {
    
    const nav = Object.keys(props.navList);
    let toggleMenuClass = (e) => {
        e.target.classList.toggle("active")
    };

    let icon = props.isHomepage ? menuIconHomepage : menuIcon;

    let slash = props.prependSlash ? "/" : "";
        return (
            <>
                <label onClick={toggleMenuClass} id="mobile-nav-toggle" htmlFor="nav-toggle">
                    <img src={icon} alt="Menu"/>
                </label>
                <input id="nav-toggle" type="checkbox"></input>
                <div id="mobile-nav">
                    <nav>
                        <ul>
                            {
                                nav.map((e) =>
                                    <li key={props.navList[e]}><a href={slash + props.navList[e]}>{e}</a></li>
                                )
                            }
                        </ul>
                    </nav>
                </div>
                <div id="desktop-nav">
                    <nav>
                        <ul>
                            {
                                nav.map((e) =>
                                    <li className="fl delay1" key={props.navList[e]}><a href={slash + props.navList[e]}>{e}</a></li>
                                )
                            }
                        </ul>
                    </nav>
                </div>
            </>
        );
};



export default Menu;
