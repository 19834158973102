import React from 'react';
import "../resources.css"

var fetchAssetUrl = function (e) {
    e.preventDefault();
    var elem = e.currentTarget;
    var id = elem.dataset.id;
    var url = `/fetch/asset/${id}`;
    elem.classList.add("loading");
    fetch(url)
        .then(res => res.json())
        .then(result => {
            setTimeout(() => {
                window.location = result.url;
                elem.classList.remove("loading");
            }, 2000);
            
        });
}

const ResourceLink = (props) => {
    const { resource } = props;
    
    return (
        <li onClick={fetchAssetUrl} data-id={resource.asset.sys.id} className="resource-link" key={resource.asset.sys.id} data-type={resource.type.toLowerCase()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="340.056" height="421.32" viewBox="0 0 340.056 421.32">
                <g id="Icon_feather-file" data-name="Icon feather-file" transform="translate(1.5 4.5)">
                    <path id="Path_34" data-name="Path 34" d="M188.844,3H46.632A40.632,40.632,0,0,0,6,43.632V368.688A40.632,40.632,0,0,0,46.632,409.32H290.424a40.632,40.632,0,0,0,40.632-40.632V145.212Z" fill="none" stroke="var(--mutedGreen)" strokeLinecap="round" strokeLinejoin="round" strokeWidth={15} />
                    <path id="Path_35" data-name="Path 35" d="M19.5,3V145.212H161.712" transform="translate(169.344)" fill="none" stroke="var(--mutedGreen)" strokeLinecap="round" strokeLinejoin="round" strokeWidth={15} />
                </g>
            </svg>
            <div>
                <a><strong>{resource.type}: </strong>{resource.title}</a>
                <p>{resource.description}</p>
            </div>

        </li>
    );
}

export default ResourceLink;
