import React from 'react';
import ListenButton from '../listen-btn';
import './play-modal.css';

const PlayModal = (props) => {
    
    let modalClasses = props.show ? "modal play-modal modal-fade-in" : "modal play-modal fade";

    let networks = [
        {
            network: "apple",
            link: props.links.itunes || ""
        },
        {
            network: "spotify",
            link: props.links.spotify || ""
        }
    ]

    const ListenButtons = networks.map(n =>
        {
        if (!props.isDefaultUrls) {
                return <ListenButton key={n.network} network={n.network} link={n.link} />
            } else {
                if (n.network === "apple") {
                    return <ListenButton key={"apple"} network={"apple"} link={"https://podcasts.apple.com/us/podcast/astroskeptic/id1574061375"} />
                } else {
                    return <ListenButton key={"spotify"} network={"spotify"} link={"https://open.spotify.com/show/6sJJ6fZwPn0o1oZUSRyZp2?si=0240ce9ec7834943"} />
                }
            }
        }
    );

    return (
        <div className={modalClasses} tabIndex={-1} role="dialog" onClick={props.onClose}>
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content" onClick={e => e.stopPropagation()}>
                    <button type="button" className="close text-right" aria-label="Close" onClick={props.onClose} >
                        <span aria-hidden="true">×</span>
                    </button>
                    <div className="modal-body text-center pt-2 pb-4">
                        {props.title}
                        <br />
                        <p>Listen now on these providers:</p>

                        <div className="listen-buttons listen-buttons-stacked mt-4 mb-3">
                            {ListenButtons}
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default PlayModal;
