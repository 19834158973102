import React from 'react';
// Needed to parse show notes:
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import parse from 'html-react-parser';
import './show-notes.css';


const ShowNotes = (props) => {

    let notesRaw = props.notes;
    
    if (notesRaw !== undefined && notesRaw.showNotes !== null && notesRaw.showNotes.showNotes !== null) {
        function noteFormatter(n) {
            return {
                renderNode: {
                    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
                        // render the EMBEDDED_ASSET as you need
                        let match = n.showNotes.showNotes.assets.filter(x => x.id === node.data.target.sys.id)[0];
                        let url = "https:" + match.url + "?q=50";
                        let aspect = (match.dimensions[1] / match.dimensions[0] * 100).toFixed(2) + "%";
                        return `<div class="inline-image" style="padding-bottom: ${aspect}">
                                    <img alt="-" src="${url}" />
                                </div>`;
                    }
                }
            }
        }

        let renderOptions = noteFormatter(notesRaw);
        
        let notes = props.notes.showNotes ? parse(documentToHtmlString(props.notes.showNotes.showNotes, renderOptions)) : null;

        

        //embedded-asset-block"

        return (
            <div id="ShowNotes">
                <h3>Episode Notes</h3>
                <div id="Notes">
                    {notes}
                </div>
            </div>
        );
    } else {
        return <div>Loading...</div>
    }
    
}


export default ShowNotes;
