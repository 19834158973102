import React from 'react'
import Menu from '../homepage-header/menu';
import SubpageLogo from './subpage-logo';
import './subpage-header.css';

function SubpageHeader(props) {



    return (
        <header id="SubpageHeader" className="container">
            <div className="row">
                <div className="col-auto">
                    <SubpageLogo />
                </div>
                <div className="col">
                    <Menu navList={props.nav} prependSlash={true} />
                </div>
            </div>
        </header>
    )
}

export default SubpageHeader
