import React from 'react';
import { withRouter } from "react-router";
import { Redirect } from 'react-router-dom';
import BigThumbnail from "./big-thumbnail";
import MetaContent from './meta-content';
import ShowNotes from './show-notes';


class EpisodeContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showNotes: null,
            episode: -1
        };
        this.identifier = this.props.match.params.identifier;
    }

    componentDidMount() {
        fetch("/fetch/episode/" + this.identifier)
            .then(res => res.json())
            .then(
                (result) => {
                    if (result !== null && result.EPISODES.length > 0) {
                        this.setState({
                            episode: result.EPISODES[0]
                        });
                    } else {
                        this.setState({
                            episode: null
                        });
                    }
                }
            ).finally( () => {
                if (this.state.episode !== null) {
                    fetch("/get_show_notes/" + this.identifier)
                        .then(res => res.json())
                        .then(
                            (result) => {
                                this.setState({
                                    showNotes: result
                                });
                            }
                        )
                }
            })
        
    }

    
    

    render() {
        
        let { episode } = this.state;

        if (episode === -1) {
            return (<div>Loading...</div>);
        } else {
            if (episode) {
                return (
                    <section id="EpisodeContent">
                        <BigThumbnail episode={episode} />
                        <MetaContent episode={episode} />
                        <ShowNotes notes={this.state} />
                    </section>
                );
            } 
            return (
                Redirect("404")
            )
            }
        }
}

export default withRouter(EpisodeContent);
