import React, {Fragment} from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga4";

// Basic Settings
import Settings from './Settings.js'

// Components
import HomepageHeader from './homepage-header';
import Heading from './heading';
import EpisodePreview from './episode-preview';
import EpisodeListingTable from './episode-listing-table';
import About from './about';
import Chart from './chart';
import NatalChart from "./_natal-chart";
import Footer from './footer';
import SubpageHeader from './subpage-header';
import EpisodeContent from './episode-content';
import SocialWidget from './social-widget';
import Instafeed from './instafeed';
import Resources from './resources';
import ContactForm from './contactform';
import revaPic from "./homepage-header/profile-bubbles/reva.jpg";

// Styles & Images
import './App.css';
import episodeHeadingIcon from './heading/episodes.svg';
import aboutHeadingIcon from './heading/about.svg';
import contactFormIcon from './heading/contact-us.svg';


ReactGA.initialize('G-7F3CS3SXNP');

function hashLinkScroll() {
  const { hash } = window.location;
  if (hash !== '') {
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementsByName(id)[0];
      if (element) element.scrollIntoView();
    },0);
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      EPISODES: [],
      TOTAL: 0,
      PAGE_LIMIT: 0,
      AUTHORS: []
    };
  }

  componentDidMount() {
    fetch("/fetch")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            EPISODES: result.EPISODES,
            TOTAL: result.META.TOTAL,
            AUTHORS: result.AUTHORS,
            PAGE_LIMIT: result.META.PAGE_LIMIT
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  };


  render() {

    const { error, isLoaded, EPISODES, AUTHORS, TOTAL, PAGE_LIMIT } = this.state;
    if (error) {
      return <div className="text-center text-white mt-5">Oops! We're having issues. Check back in ten minutes.</div>;
    } else if (!isLoaded) {
      return <div className="text-center text-white mt-5">Loading...</div>;
    } else {
      hashLinkScroll()
      return (
        <Router>
          <div id="PageWrapper" className="container">
            <div id="App">
              <SocialWidget links={Settings.Socials} />
              <Switch>
                {/* HOME */}
              <Route path="/" exact>
                <Fragment>
                  <HomepageHeader
                    nav={Settings.Nav}
                    siteTitle={Settings.LongSiteTitle}
                    introText={Settings.IntroText}
                    serviceUrls={Settings.ServiceUrls}
                  />
                  <main>
                    <Heading
                      text={`Episodes`}
                      centered={true}
                      icon={episodeHeadingIcon}
                      navFor={Settings.Nav.episodes}
                    />
                    <EpisodePreview episode={EPISODES[0]} />
                    <EpisodeListingTable episodes={EPISODES} total={TOTAL} pageLimit={PAGE_LIMIT} />
                    <div className="pt-3"></div>
                    <Heading
                      text={`About`}
                      centered={true}
                      icon={aboutHeadingIcon}
                      navFor={Settings.Nav.about}
                    />
                    <About authors={AUTHORS} />
                    <Chart navFor={Settings.Nav.chart} />
                    <Heading
                      text={`Contact Us`}
                      centered={true}
                      icon={contactFormIcon}
                      navFor={null}
                    />
                    <ContactForm />
                    <Heading
                      text={`Instagram`}
                      centered={true}
                      icon={null}
                      navFor={null}
                    />
                    <h4>{Settings.Socials.map(x => x.name === "Instagram")[0]}</h4>
                    <Instafeed />
                  </main>
                </Fragment>
              </Route>
              {/* EPISODE PAGE */}
              <Route path="/episodes/:identifier" exact>
                <Fragment>
                  <SubpageHeader nav={Settings.Nav} />
                  <main>
                    <EpisodeContent episodes={EPISODES} />
                  </main>
                </Fragment>
              </Route>
              {/* RESOURCES PAGE */}
              <Route path="/resources" exact>
                <Fragment>
                  <SubpageHeader nav={Settings.Nav} />
                  <main>
                    <Resources />
                  </main>
                </Fragment>
              </Route>
              {/* CHART PAGE */}
              <Route path="/chart" exact>
                <Fragment>
                  <SubpageHeader nav={Settings.Nav} />
                  <main>
                    <NatalChart />
                  </main>
                </Fragment>
              </Route>
              {/* WHERE'S REVA PAGE */}
              <Route path="/wheres-reva" exact>
                <Fragment>
                  <SubpageHeader nav={Settings.Nav} />
                  <main>
                    <img id="wheres-reva-pic" src={revaPic}></img>
                    <h1>Where&apos;s Reva?</h1>
                    <p>{AUTHORS[0].bio}</p>
                  </main>
                </Fragment>
              </Route>
              <Route path="*">
                <Fragment>
                  <SubpageHeader nav={Settings.Nav} />
                  <main>
                   <h1>404</h1>
                   <h3>Page not found</h3>
                  </main>
                </Fragment>
              </Route>
              </Switch>
              
              <Footer nav={Settings.Nav} urls={Settings.ServiceUrls} />
            </div>
          </div>
        </Router>
      );
    }
  }
};


export default App;
