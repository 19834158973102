import React, { useState } from 'react';
import './episode-preview.css';
import playBtn from './play.svg';
import TagList from '../tag-list';
import PlayModal from '../play-modal';
import EpisodeMeta from './episode-meta';



const EpisodePreview = (props) => {

    const [show, setShow] = useState(false);

    let newTag = props.episode.isLatest ? <div className="episode-new-tag">latest</div> : null;
    let formattedTitle = props.episode.title.split(": ").length > 1 ? <h4><small>{props.episode.title.split(": ")[0]}:</small><br /><span>{props.episode.title.split(": ")[1]}</span></h4> : <h4>{props.episode.title}</h4>;
    

    let urls = {
        spotify: props.episode.spotifyUrl,
        itunes: props.episode.itunesUrl
    };

    return (
        <figure className="episode-preview">
            <div className="row align-items-center">
                <div className="d-none d-md-block col-md-auto">
                    <div className="episode-thumb" onClick={() => { setShow(true) }}>
                        <img src={props.episode.thumbnail} alt="test" />
                        <span>listen</span>
                        <img className="play" src={playBtn} alt="play" />
                    </div>
                </div>
                <div className="col-12 col-sm align-self-center">
                    <div className="episode-meta">
                        {newTag}
                        {formattedTitle}
                        <p>{props.episode.description}</p>
                        <div className="row">
                            <div className="col-12 col-sm-auto">
                                <button className="btn btn-primary mr-2 d-inline-block d-md-none" onClick={() => { setShow(true) }}>Play Now</button>
                                <a className="btn btn-secondary" href={props.episode.nav.current}>View Notes</a>
                            </div>
                            <div className="col-12 col-sm-auto">
                                <EpisodeMeta date={props.episode.releaseDate} duration={props.episode.duration}></EpisodeMeta>
                                <div className="episode-meta-topics">
                                    <span>Topics: </span>
                                    <TagList tags={props.episode.topics} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PlayModal onClose={() => setShow(false)} show={show} title={formattedTitle} links={urls} isDefaultUrls={false} />
        </figure>
    );

}


export default EpisodePreview;
