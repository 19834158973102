import React from 'react';
import './listen-btn.css';

const ListenButton = (props) => {
    let button;
    let btnClasses = (props.link !== "") ? "btn listen-button" : "btn listen-button disabled";
    switch (props.network) {
        case "apple":
            button = (
              <a
                className={btnClasses}
                data-icon="apple"
                href={props.link}
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16.551"
                  height="18.915"
                  viewBox="0 0 16.551 18.915"
                >
                  <path
                    id="Icon_awesome-podcast"
                    data-name="Icon awesome-podcast"
                    d="M9.88,18.049c-.19.7-.908.866-1.6.866s-1.414-.164-1.6-.866a26.92,26.92,0,0,1-.76-4.907c0-1.3,1.151-1.616,2.364-1.616s2.364.317,2.364,1.616A26.947,26.947,0,0,1,9.88,18.049ZM5.8,10.66a3.547,3.547,0,1,1,4.96,0,.222.222,0,0,0,.023.339,2.458,2.458,0,0,1,.784.937.22.22,0,0,0,.349.07,5.32,5.32,0,1,0-7.274,0,.22.22,0,0,0,.349-.07A2.458,2.458,0,0,1,5.772,11,.222.222,0,0,0,5.8,10.66ZM8.275,0a8.278,8.278,0,0,0-3.63,15.716.222.222,0,0,0,.315-.233c-.088-.573-.16-1.143-.2-1.638a.223.223,0,0,0-.1-.167,6.5,6.5,0,1,1,7.136.06c-.037.519-.113,1.13-.208,1.745a.222.222,0,0,0,.315.233A8.278,8.278,0,0,0,8.275,0Zm0,5.911A2.364,2.364,0,1,0,10.64,8.275,2.364,2.364,0,0,0,8.275,5.911Z"
                  />
                </svg>
                Listen on Apple Podcasts
              </a>
            );
            break;
        case "spotify":
            button = (
              <a
                className={btnClasses}
                data-icon="spotify"
                href={props.link}
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.279"
                  height="17.279"
                  viewBox="0 0 17.279 17.279"
                >
                  <path
                    id="Icon_awesome-spotify"
                    data-name="Icon awesome-spotify"
                    d="M8.639.563A8.639,8.639,0,1,0,17.279,9.2,8.642,8.642,0,0,0,8.639.563Zm3.508,12.712a.665.665,0,0,1-.373-.125,9.832,9.832,0,0,0-7.2-.853,2.249,2.249,0,0,1-.415.091.542.542,0,0,1-.55-.55.552.552,0,0,1,.474-.585,11.121,11.121,0,0,1,8.256.913.589.589,0,0,1,.338.575.526.526,0,0,1-.53.536Zm.937-2.285a.86.86,0,0,1-.428-.146A11.289,11.289,0,0,0,4.344,9.819a1.435,1.435,0,0,1-.415.091.676.676,0,0,1-.676-.676.685.685,0,0,1,.54-.721A11.717,11.717,0,0,1,7.2,8.038a12.2,12.2,0,0,1,6.166,1.585.726.726,0,0,1,.394.686A.675.675,0,0,1,13.085,10.989Zm1.08-2.655a.8.8,0,0,1-.449-.136C11.235,6.718,6.8,6.363,3.93,7.164a1.655,1.655,0,0,1-.449.091.8.8,0,0,1-.812-.822A.823.823,0,0,1,3.275,5.6a14.6,14.6,0,0,1,4.093-.53,14.713,14.713,0,0,1,7.155,1.665.818.818,0,0,1,.449.787.807.807,0,0,1-.808.812Z"
                    transform="translate(0 -0.563)"
                  />
                </svg>
                Listen on Spotify
              </a>
            );
            break;
        default:
            button = null;
    }

    return button
}

export default ListenButton;
