import React from 'react';
import EpisodeListingTableRow from './episode-listing-table-row';

class EpisodeListingTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            episodes: props.episodes,
            total: props.total,
            pageLimit: props.pageLimit,
            currentPage: 0
        };
    };

    GetPage(page) {
        if (page !== null) {
            fetch(`/fetch/${page}`).then(res => res.json()).then( res => {
                this.setState({episodes: res.EPISODES, currentPage: parseInt(res.META.CURRENT_PAGE)});
            })
        }
    };

    render() {

        var rows = this.state.episodes.map(ep =>
            <EpisodeListingTableRow episode={ep} key={ep.title} />
        );
        
        var p = 0;
        var paginationItems = [];
        var pagination = Math.ceil(this.state.total / this.state.pageLimit);


        var paginationPrev = null;
        var paginationNext = null;
        
        while (p < pagination ) {
            paginationItems.push({p});
            p++;
        }

        if (this.state.currentPage <= pagination && this.state.currentPage !== 0) paginationPrev = <li className='page-item'><a className="page-link" onClick={ () => { this.GetPage(parseInt(this.state.currentPage) - 1 )}}>Prev</a></li>;
        if (this.state.currentPage <= pagination && this.state.currentPage + 1 !== pagination) paginationNext = <li className='page-item'><a className="page-link" onClick={ () => { this.GetPage(parseInt(this.state.currentPage) + 1)}}>Next</a></li>;
        
        paginationItems = paginationItems.map(p => <li key={p.p + 1} className="page-item" data-is-active={p.p == this.state.currentPage ? "true" : "false"} ><a data-page={p.p + 1} className="page-link" onClick={ () => { this.GetPage(p.p)}}>{p.p + 1}</a></li>)

        return (
            <div id="EpisodeListingTable" className="table-wrapper">
                <table className="table">
                    <thead>
                        <tr>
                            <td data-contains="beacon">&nbsp;</td>
                            <td data-contains="id">ID</td>
                            <td data-contains="title">Title</td>
                            <td data-contains="topics">Topics</td>
                            <td data-contains="date">Date</td>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
                <nav aria-label="Page navigation example">
                    <ul className="pagination">
                        {paginationPrev}
                        {paginationItems}
                        {paginationNext}
                    </ul>
                </nav>
            </div>
        );
    }
}


export default EpisodeListingTable;
