import React from 'react';
import paigePic from './paige_astroskeptic.jpg';
import Bio from './bio';
import './about.css';

const About = (props) => {

    // Show Reva?
    let showReva = false;

    // List Paige on the left
    let auths = props.authors.sort((a, b) => {
          return a.name === "Paige" ? -1 : 0;
        }).map(a => 
        <Bio name={a.name} bio={a.bio} key={a.name} props={showReva} />
    )

    if (!showReva) {
        auths = auths.shift();
    }

    return (
        <section id="About">
            <div className="row justify-content-center">
                <div className="col-12 col-md-8">
                    <img className="img rounded polaroid d-block m-auto" style={{ maxWidth: 300 }} src={paigePic} alt="Meet the Hosts: Paige and Reva" />
                </div>
            </div>
            <div className="mt-4 row justify-content-center">
                <div className="col-12">
                    <div className="row">
                        {auths}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
